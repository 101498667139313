header {
    position: fixed;
    top: 0; right: 0; left: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    
    padding: 20px;
    z-index: 999;

    a {

        &:not(.button) {
            height: 26px;

            svg {
                width: auto;
                height: 100%;
    
                fill: var(--inverted);
    
                &:hover {
                    animation: svg-color-switch 3s infinite linear;
                }
            }    
        }

        &.button {
            padding: 6px 10px;
        }
    }

    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        margin: 0 20px 0 auto;

        li {

            button {
                @include btn-reset();
                padding: 6px 10px;

                font-family: $text-font;
                font-size: 13px;
                color: var(--inverted);
                opacity: 0.6;

                &.selected {
                    font-weight: 700;
                }
            }

            &:not(:last-of-type) {
                position: relative;

                &:after {
                    content: '/';
                    display: inline-block;

                    position: absolute;
                    right: -4px; top: 50%;
                    transform: translateY(-50%);
                }
            }

            [data-color-scheme="light"] & {
                    
                &:first-of-type {

                    button {
                        font-weight: 700;
                        opacity: 1;
                    }
                }

                &:last-of-type {

                    button {
                        cursor: pointer;
                    }
                }
            }

            [data-color-scheme="dark"] & {

                &:first-of-type {

                    button {
                        cursor: pointer;
                    }
                }
                    
                &:last-of-type {

                    button {
                        font-weight: 700;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

main {

    section {
        position: relative;
        padding: 0 20px;

        &[style*="hero"] {

            > div {
                height: calc(100vh + 1px);
                padding: 220px 80px;

                display: flex;
                flex-direction: column;
                justify-content: center;

                border-bottom: 1px solid var(--inverted);

                @include lg {
                    padding: 220px 60px;
                }

                @include sm {
                    height: calc((var(--vh) * 100) + 1px);

                    justify-content: flex-start;
                    padding: 100px 0 0;
                }

                figure {                    
                    position: absolute;
                    top: 0; right: 0; bottom: 0; left: 0;
                    z-index: 1;

                    @include sm {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                    }
                }
    
                h1,
                p {
                    position: relative;
                    z-index: 2;
                }
    
                h1 {
                    max-width: 1080px;
                    margin: 0 0 80px;

                    @include lg {
                        max-width: 800px;
                        margin: 0 0 50px;
                    }

                    @include sm {
                        max-width: 560px;
                        margin: 0 0 30px;
                    }

                    @include xs {
                        margin: 0 0 15px;
                    }
                }
    
                p {
                    opacity: 0.5;

                    span {

                        &:first-of-type {

                            @include sm {
                                display: none;
                            }
                        }

                        &:last-of-type {

                            @include sm-up {
                                display: none;
                            }
                        }
                    }
                }    
            }
        }

        &[style*="about"] {

            div {
                width: 100%;
                max-width: 800px;                
                margin: 0 auto;

                @include lg {
                    max-width: 660px;
                }

                div {
                    height: 100vh;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;        
                }
            }

            p {                
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                gap: 0 20px;
                margin: 0;

                font-size: 28px;
                color: var(--inverted);

                @include lg {
                    font-size: 22px;
                }

                @include sm {
                    font-size: 24px;
                }

                @include xs {
                    font-size: 20px;
                }

                @include height-xxs {
                    font-size: 18px;
                }

                &:not(:last-child) {
                    margin: 0 0 60px;
                }

                span {
                    opacity: 0.3;
                    transition: 0.2s all;

                    &.read {
                        opacity: 1;
                    }
                }
            }
        }

        &[style*="services"] {
            padding: 0 100px 250px;

            @include lg {
                padding: 0 80px 160px;
            }

            @include sm {
                padding: 0 20px 140px;
            }

            @include xs {
                padding: 0 20px 135px;
            }

            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 40px 80px;

                @include lg {
                    gap: 40px 50px;
                }

                @include sm {
                    flex-direction: column;
                    gap: 40px;
                }

                li {
                    width: calc(33.3% - 54px);
                    padding: 0 0 40px;

                    border-bottom: 1px solid var(--inverted);

                    @include lg {
                        width: calc(33.3% - 33px);
                    }

                    @include sm {
                        width: 100%;
                    }

                    h3 {
                        margin: 0 0 30px;

                        @include lg {
                            margin: 0 0 20px;
                        }
                    }

                    p {
                        margin: 0;
                        font-size: 16px;
                        color: var(--inverted);
                        opacity: 0.5;

                        @include lg {
                            font-size: 15px;
                        }

                        @include xs {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        &[style*="projects"] {

            ol {
                padding: 0;

                counter-reset: main-counter;
                list-style-type: none;

                > li {

                    &:first-of-type {
                        padding: 0 0 16px;

                        ul {
                            display: flex;
                            flex-direction: row;
                            gap: 20px;

                            li {

                                &:first-child {
                                    width: calc(5% - 16.6px);

                                    @include sm {
                                        width: calc(10% - 15px);
                                    }
                                }

                                &:nth-child(2) {
                                    width: calc(30% - 16.6px);

                                    @include sm {
                                        width: calc(60% - 15px);
                                    }
                                }
        
                                &:nth-child(3) {
                                    width: calc(8% - 16.6px);

                                    @include sm {
                                        width: calc(15% - 15px);
                                    }
                                }
        
                                &:nth-child(4) {
                                    width: calc(22% - 16.6px);

                                    @include sm {
                                        display: none;
                                    }
                                }
        
                                &:nth-child(5) {
                                    width: calc(15% - 16.6px);

                                    @include sm {
                                        display: none;
                                    }
                                }
        
                                &:last-child {
                                    width: calc(20% - 16.6px);

                                    @include sm {
                                        width: calc(15% - 15px);
                                    }
                                }        
                            }
                        }
                    }

                    &:not(:first-of-type) {
                        height: 82px;

                        display: flex;
                        flex-direction: row;
                        gap: 20px;

                        padding: 16px 0;

                        border-top: 1px solid var(--inverted);
                        counter-increment: main-counter;
    
                        &:before {
                            display: block;
                            content: counter(main-counter);
    
                            width: calc(5% - 16.6px);
                            position: relative;
    
                            font-size: 16px;

                            @include sm {
                                width: calc(10% - 15px);
                                margin: auto 0;
                            }
                        }
    
                        &:nth-of-type(-n + 10) {
    
                            &:before {
                                content: '0' counter(main-counter);
                            }    
                        }    
                    }

                    &:last-of-type {
                        border-bottom: 1px solid var(--inverted);
                    }

                    > span,
                    > a {
                        margin: auto 0;

                        &:first-child {
                            width: calc(30% - 16.6px);
                            font-size: 20px;
                            text-decoration: none;

                            @include sm {
                                width: calc(60% - 13px);
                                font-size: 16px;
                            }
                        }

                        &:nth-child(2) {
                            width: calc(8% - 16.6px);

                            @include sm {
                                width: calc(15% - 15px);
                            }
                        }

                        &:nth-child(3) {
                            width: calc(22% - 16.6px);

                            @include sm {
                                display: none;
                            }
                        }

                        &:nth-child(4) {
                            width: calc(15% - 16.6px);

                            @include sm {
                                display: none;
                            }
                        }

                        &:last-child {
                            width: calc(20% - 16.6px);
                            text-align: right;

                            @include sm {
                                width: calc(15% - 15px);
                            }

                            svg {
                                color: var(--inverted);

                                @include sm {
                                    width: 36px;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        &[style*="testimonials"] {
            padding: 0;
            overflow: hidden;

            @include xs {
                padding: calc(100px + 35px) 0 100px;
            }

            ul {
                height: 100vh;
                gap: 200px;

                transform: translate(calc((100vw - 1240px) / 2), 0);
                -webkit-text-size-adjust: 100%;

                @include lg {
                    gap: 100px;
                    transform: translate(calc((100vw - 1000px) / 2), 0);
                }

                @include sm {
                    gap: 0;
                    transform: none;
                }

                @include xs {
                    height: auto;
                }

                &,
                li {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @include xs {
                        align-items: flex-start;
                    }
                }

                li {
                    width: 100%;
                    max-width: 1240px;

                    flex-shrink: 0;
                    gap: 100px;

                    @include lg {
                        max-width: 1000px;
                        gap: 80px;
                    }

                    @include sm {
                        flex-direction: column;
                        align-items: flex-start;
                        
                        max-width: 600px;
                        gap: 40px;
                    }

                    @include xs {
                        width: 100%;
                        gap: 20px;
                        padding: 0 30px;
                    }

                    &:first-of-type,
                    &:nth-of-type(3) {

                        blockquote {

                            p {
                                
                                &:last-of-type {

                                    @include xxs {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-of-type(2) {

                        blockquote {

                            p {

                                &:first-of-type {

                                    @include xxs {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    
                    figure {
                        width: 350px;
                        height: 450px;

                        @include lg {
                            width: 262px;
                            height: 338px;
                        }

                        @include sm {
                            width: 125px;
                            height: 160px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    blockquote {
                        margin: 0 0 40px;

                        @include xs {
                            margin: 0 0 25px;
                        }

                        p {
                            font-size: 20px;
                            color: var(--inverted);

                            @include lg {
                                font-size: 18px;
                            }

                            @include xs {
                                font-size: 14px;
                            }

                            @include xxs {
                                font-size: 17px;
                            }
                        }
                    }
                    
                    cite {

                        strong,
                        span {
                            display: block;

                            font-weight: 400;
                            font-style: normal;
                            line-height: 150%;
                        }

                        strong {
                            font-size: 16px;

                            @include lg {
                                font-size: 15px;
                            }

                            @include xs {
                                font-size: 12px;
                            }

                            @include xxs {
                                font-size: 14px;
                            }
                        }

                        span {
                            font-size: 13px;

                            @include xs {
                                font-size: 12px;
                            }

                            @include xxs {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }

        &[style*="contact"] {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            padding-bottom: 160px;

            @include sm {
                padding-bottom: 80px;
            }

            div {
                width: 100%;
                max-width: 800px;
            }

            h2 {
                margin: 0 0 30px;
            }

            ul {
                max-height: calc(100vh - 138px);
                padding: 0 25px 25px 0;

                .simplebar-vertical {
                    top: 16px;
                    bottom: 16px;

                    .simplebar-scrollbar {

                        &:before {
                            opacity: 0.1;
                        }
                    }
                }

                .simplebar-content {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;    
                }

                li {
                    position: relative;

                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    p {
                        position: relative;
                        width: auto;
                        max-width: 430px;

                        padding: 12px 16px;    
                        margin: 0;

                        border-radius: 10px;
                        border: 1px solid var(--inverted);

                        line-height: 135%;
                        animation: var(--anim);

                        .btn {
                            position: absolute;
                            left: calc(100% + 12px);
                            top: 50%;

                            white-space: nowrap;
                            transform: translate(0, -50%);
                        }
                    }

                    &.in {
                        padding-right: 4px;

                        p {
                            margin-left: auto;
                            color: var(--straight);
                            background-color: var(--inverted);

                            &:last-of-type {
                                border-radius: 10px 10px 0 10px;
                            }
                        }
                    }

                    &.out {
                        padding-left: 35px;

                        p {
                            margin-right: auto;
                            color: var(--inverted);

                            &:last-of-type {
                                border-radius: 10px 10px 10px 0;
                            }

                            a {
                                font-weight: 700;
                                text-decoration: underline;
                            }

                            &.available {

                                strong {
                                    
                                    &:before {
                                        @include pseudo-el();
    
                                        width: 8px;
                                        height: 8px;
    
                                        display: inline-block;
                                        margin: 0 8px 0 0;

                                        position: relative;
                                        top: -1px;
    
                                        border-radius: 50%;
                                        background-color: $green-1;

                                        @include xxs {
                                            top: 0;
                                        }
                                    }    
                                }
                            }
                        }

                        figure {
                            position: absolute;
                            left: 0; bottom: 0;
                        }
                    }
                }
            }

            form {
                position: relative;
                margin-top: auto;

                display: flex;
                flex-direction: row;
                align-items: center;

                label {
                    position: relative;
                    width: 100%;

                    input.btn {
                        width: 100%;
                        padding: 17px 20px;

                        font-family: var(--text-font);
                        font-size: 16px;
                        color: var(--inverted);

                        border-radius: 0;
                        border: 1px solid transparent;
                        border-bottom: 1px solid var(--inverted);

                        cursor: text;
                        background: none;

                        @include sm {
                            font-size: 14px;
                        }

                        @include placeholder() {
                            color: var(--inverted)
                        }

                        &[disabled] {

                            @include placeholder() {
                                color: var(--straight)
                            }
                        }

                        &:focus {
                            border-bottom: 1px dashed var(--inverted);
                            outline: none !important;
                        }
                    }

                    svg {
                        width: 70px;
                        height: 70px;

                        position: absolute;
                        left: 20px; 
                        top: calc(50% - 35px);

                        display: none;
                    }
                }

                button {

                    &[type="submit"] {
                        @include btn-reset();

                        width: 60px;
                        height: 60px;
                        flex-shrink: 0;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        color: var(--inverted);
                        border: none;
                        border-bottom: 1px solid var(--inverted);

                        @include sm {
                            width: 57px;
                            height: 57px;
                        }

                        &:not([disabled]) {
                            cursor: pointer;
                            transition: 0.2s all;

                            &:hover {
                                
                                @include sm-up {
                                    color: var(--straight);
                                    background: var(--inverted);
                                }
                            }
                        }

                        &[disabled] {
                            cursor: not-allowed;
                        }

                        svg {
                            width: 24px;
                            height: 24px;
                        }    
                    }

                    &[type="button"] {
                        @include btn-reset();

                        position: absolute;
                        top: 50%;
                        right: 20px;

                        font-family: var(--text-font);
                        font-size: 12px;
                        font-weight: 600;
                        color: var(--inverted);
                        text-decoration: underline;

                        white-space: nowrap;
                        transform: translate(0, -50%);
                    }
                }
            }
        }
    }
}

footer {
    padding: 0 20px;

    > ul {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 40px 0;
        border-top: 1px solid var(--inverted);

        @include sm {
            flex-wrap: wrap;
            justify-content: center;
            gap: 30px 0;
        }

        > li {

            &:first-of-type {
                margin: 0 50px 0 0;

                > a {
                    display: block;
                    line-height: 1;
                    
                    svg {
                        width: auto;
                        height: 35px;
                        fill: var(--inverted);

                        @include sm {
                            height: 25px;
                        }

                        &:hover {
                            animation: svg-color-switch 3s infinite linear;
                        }            
                    }
                }        
            }

            &:nth-of-type(2) {

                ul {
        
                    li {
                        line-height: 1;
        
                        &:not(:last-child) {
                            margin: 0 0 9px;
                        }
                    }
                }
            }

            &:last-of-type {
                margin-left: auto;
                padding-right: 10px;

                @include sm {
                    width: 100%;

                    margin: 0 auto;
                    padding: 0;

                    text-align: center;
                }

                span {

                    @include sm {
                        font-size: 13px;
                    }

                    @include xs {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}