* {
    box-sizing: border-box;
}

html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    padding: 0;

    font-family: $text-font;
    font-size: 13px;
    line-height: 150%;
    color: var(--inverted);

    background-color: var(--straight);
    transition: 0.3s background;

    &[data-color-scheme="dark"] {
        --straight: #{$dark-1};
        --inverted: #{$white};
    }

    &[data-color-scheme="light"] {
        --straight: #{$white};
        --inverted: #{$dark-1};
    }

    #magicMouseCursor {
        border-color: var(--inverted) !important;
    }

    #magicPointer {
        background: var(--inverted) !important;
    }
}

p {
    margin: 0;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

a,
button {
    color: var(--inverted);
}

.container {
    width: 100%;
    max-width: calc(540px + 100px);
    
    padding: 0 50px;
    margin: 0 auto;

    @include sm {
        padding: 0 24px;
    }
}

h1,
h2,
h3 {
    margin: 0;

    font-family: $text-font;
    font-weight: 500;
}

h1 {
    font-size: 54px;
    line-height: 1.35;

    @include lg {
        font-size: 42px;
    }

    @include sm {
        font-size: 30px;
    }

    @include xs {
        font-size: 21px;
    }
}

h2 {
    font-size: 48px;
    line-height: 1.2;
    letter-spacing: -0.09px;
}

h3 {
    font-size: 28px;
    line-height: 1.1;
    letter-spacing: -0.09px;

    @include lg {
        font-size: 22px;
    }

    @include sm {
        font-size: 20px;
    }

    @include xs {
        font-size: 18px;
    }
}

p {
    font-size: 15px;
    line-height: 170%;
    color: var(--inverted);

    @include lg {
        font-size: 14px;
    }

    @include xs {
        font-size: 13px;
    }

    &:not(:last-of-type) {
        margin: 0 0 20px;
    }
}

.button {
    @include btn-reset();

    font-size: 13px;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
        animation: text-color-switch 3s infinite linear;
    }
}

figure {
    margin: 0;
    flex-shrink: 0;

    &.round {
        width: 25px;
        height: 25px;

        @include sm {
            width: 22px;
            height: 22px;
        }

        &,
        img {
            border-radius: 50%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}