@keyframes text-color-switch {
  0% {
    color: #FE45B2;
  }
  20% {
    color: #A235FF;
  }
  40% {
    color: #1CE4C9;
  }
  60% {
    color: #FFD437;
  }
  80% {
    color: #00C4FF;
  }
  100% {
    color: #E55807;
  }
}
@keyframes svg-color-switch {
  0% {
    fill: #FE45B2;
  }
  20% {
    fill: #A235FF;
  }
  40% {
    fill: #1CE4C9;
  }
  60% {
    fill: #FFD437;
  }
  80% {
    fill: #00C4FF;
  }
  100% {
    fill: #E55807;
  }
}
@keyframes bubble-appear {
  from {
    opacity: 0;
    transform: translate(0, 20px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
* {
  box-sizing: border-box;
}

html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Space Mono", sans-serif;
  font-size: 13px;
  line-height: 150%;
  color: var(--inverted);
  background-color: var(--straight);
  transition: 0.3s background;
}
body[data-color-scheme=dark] {
  --straight: #030618;
  --inverted: #fff;
}
body[data-color-scheme=light] {
  --straight: #fff;
  --inverted: #030618;
}
body #magicMouseCursor {
  border-color: var(--inverted) !important;
}
body #magicPointer {
  background: var(--inverted) !important;
}

p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a,
button {
  color: var(--inverted);
}

.container {
  width: 100%;
  max-width: 640px;
  padding: 0 50px;
  margin: 0 auto;
}
@media only screen and (max-width: 1024px) {
  .container {
    padding: 0 24px;
  }
}

h1,
h2,
h3 {
  margin: 0;
  font-family: "Space Mono", sans-serif;
  font-weight: 500;
}

h1 {
  font-size: 54px;
  line-height: 1.35;
}
@media only screen and (max-width: 1480px) {
  h1 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 21px;
  }
}

h2 {
  font-size: 48px;
  line-height: 1.2;
  letter-spacing: -0.09px;
}

h3 {
  font-size: 28px;
  line-height: 1.1;
  letter-spacing: -0.09px;
}
@media only screen and (max-width: 1480px) {
  h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1024px) {
  h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  h3 {
    font-size: 18px;
  }
}

p {
  font-size: 15px;
  line-height: 170%;
  color: var(--inverted);
}
@media only screen and (max-width: 1480px) {
  p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  p {
    font-size: 13px;
  }
}
p:not(:last-of-type) {
  margin: 0 0 20px;
}

.button {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  font-size: 13px;
  font-weight: 700;
  text-decoration: underline;
}
.button:hover {
  animation: text-color-switch 3s infinite linear;
}

figure {
  margin: 0;
  flex-shrink: 0;
}
figure.round {
  width: 25px;
  height: 25px;
}
@media only screen and (max-width: 1024px) {
  figure.round {
    width: 22px;
    height: 22px;
  }
}
figure.round,
figure.round img {
  border-radius: 50%;
}
figure.round img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  z-index: 999;
}
header a:not(.button) {
  height: 26px;
}
header a:not(.button) svg {
  width: auto;
  height: 100%;
  fill: var(--inverted);
}
header a:not(.button) svg:hover {
  animation: svg-color-switch 3s infinite linear;
}
header a.button {
  padding: 6px 10px;
}
header ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 20px 0 auto;
}
header ul li button {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 6px 10px;
  font-family: "Space Mono", sans-serif;
  font-size: 13px;
  color: var(--inverted);
  opacity: 0.6;
}
header ul li button.selected {
  font-weight: 700;
}
header ul li:not(:last-of-type) {
  position: relative;
}
header ul li:not(:last-of-type):after {
  content: "/";
  display: inline-block;
  position: absolute;
  right: -4px;
  top: 50%;
  transform: translateY(-50%);
}
[data-color-scheme=light] header ul li:first-of-type button {
  font-weight: 700;
  opacity: 1;
}
[data-color-scheme=light] header ul li:last-of-type button {
  cursor: pointer;
}
[data-color-scheme=dark] header ul li:first-of-type button {
  cursor: pointer;
}
[data-color-scheme=dark] header ul li:last-of-type button {
  font-weight: 700;
  opacity: 1;
}

main section {
  position: relative;
  padding: 0 20px;
}
main section[style*=hero] > div {
  height: calc(100vh + 1px);
  padding: 220px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--inverted);
}
@media only screen and (max-width: 1480px) {
  main section[style*=hero] > div {
    padding: 220px 60px;
  }
}
@media only screen and (max-width: 1024px) {
  main section[style*=hero] > div {
    height: calc(var(--vh) * 100 + 1px);
    justify-content: flex-start;
    padding: 100px 0 0;
  }
}
main section[style*=hero] > div figure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
@media only screen and (max-width: 1024px) {
  main section[style*=hero] > div figure {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
main section[style*=hero] > div h1,
main section[style*=hero] > div p {
  position: relative;
  z-index: 2;
}
main section[style*=hero] > div h1 {
  max-width: 1080px;
  margin: 0 0 80px;
}
@media only screen and (max-width: 1480px) {
  main section[style*=hero] > div h1 {
    max-width: 800px;
    margin: 0 0 50px;
  }
}
@media only screen and (max-width: 1024px) {
  main section[style*=hero] > div h1 {
    max-width: 560px;
    margin: 0 0 30px;
  }
}
@media only screen and (max-width: 767px) {
  main section[style*=hero] > div h1 {
    margin: 0 0 15px;
  }
}
main section[style*=hero] > div p {
  opacity: 0.5;
}
@media only screen and (max-width: 1024px) {
  main section[style*=hero] > div p span:first-of-type {
    display: none;
  }
}
@media only screen and (min-width: 1025px) {
  main section[style*=hero] > div p span:last-of-type {
    display: none;
  }
}
main section[style*=about] div {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
@media only screen and (max-width: 1480px) {
  main section[style*=about] div {
    max-width: 660px;
  }
}
main section[style*=about] div div {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
main section[style*=about] p {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 20px;
  margin: 0;
  font-size: 28px;
  color: var(--inverted);
}
@media only screen and (max-width: 1480px) {
  main section[style*=about] p {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1024px) {
  main section[style*=about] p {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  main section[style*=about] p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 475px) and (max-height: 780px) {
  main section[style*=about] p {
    font-size: 18px;
  }
}
main section[style*=about] p:not(:last-child) {
  margin: 0 0 60px;
}
main section[style*=about] p span {
  opacity: 0.3;
  transition: 0.2s all;
}
main section[style*=about] p span.read {
  opacity: 1;
}
main section[style*=services] {
  padding: 0 100px 250px;
}
@media only screen and (max-width: 1480px) {
  main section[style*=services] {
    padding: 0 80px 160px;
  }
}
@media only screen and (max-width: 1024px) {
  main section[style*=services] {
    padding: 0 20px 140px;
  }
}
@media only screen and (max-width: 767px) {
  main section[style*=services] {
    padding: 0 20px 135px;
  }
}
main section[style*=services] ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px 80px;
}
@media only screen and (max-width: 1480px) {
  main section[style*=services] ul {
    gap: 40px 50px;
  }
}
@media only screen and (max-width: 1024px) {
  main section[style*=services] ul {
    flex-direction: column;
    gap: 40px;
  }
}
main section[style*=services] ul li {
  width: calc(33.3% - 54px);
  padding: 0 0 40px;
  border-bottom: 1px solid var(--inverted);
}
@media only screen and (max-width: 1480px) {
  main section[style*=services] ul li {
    width: calc(33.3% - 33px);
  }
}
@media only screen and (max-width: 1024px) {
  main section[style*=services] ul li {
    width: 100%;
  }
}
main section[style*=services] ul li h3 {
  margin: 0 0 30px;
}
@media only screen and (max-width: 1480px) {
  main section[style*=services] ul li h3 {
    margin: 0 0 20px;
  }
}
main section[style*=services] ul li p {
  margin: 0;
  font-size: 16px;
  color: var(--inverted);
  opacity: 0.5;
}
@media only screen and (max-width: 1480px) {
  main section[style*=services] ul li p {
    font-size: 15px;
  }
}
@media only screen and (max-width: 767px) {
  main section[style*=services] ul li p {
    font-size: 14px;
  }
}
main section[style*=projects] ol {
  padding: 0;
  counter-reset: main-counter;
  list-style-type: none;
}
main section[style*=projects] ol > li:first-of-type {
  padding: 0 0 16px;
}
main section[style*=projects] ol > li:first-of-type ul {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
main section[style*=projects] ol > li:first-of-type ul li:first-child {
  width: calc(5% - 16.6px);
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li:first-of-type ul li:first-child {
    width: calc(10% - 15px);
  }
}
main section[style*=projects] ol > li:first-of-type ul li:nth-child(2) {
  width: calc(30% - 16.6px);
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li:first-of-type ul li:nth-child(2) {
    width: calc(60% - 15px);
  }
}
main section[style*=projects] ol > li:first-of-type ul li:nth-child(3) {
  width: calc(8% - 16.6px);
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li:first-of-type ul li:nth-child(3) {
    width: calc(15% - 15px);
  }
}
main section[style*=projects] ol > li:first-of-type ul li:nth-child(4) {
  width: calc(22% - 16.6px);
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li:first-of-type ul li:nth-child(4) {
    display: none;
  }
}
main section[style*=projects] ol > li:first-of-type ul li:nth-child(5) {
  width: calc(15% - 16.6px);
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li:first-of-type ul li:nth-child(5) {
    display: none;
  }
}
main section[style*=projects] ol > li:first-of-type ul li:last-child {
  width: calc(20% - 16.6px);
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li:first-of-type ul li:last-child {
    width: calc(15% - 15px);
  }
}
main section[style*=projects] ol > li:not(:first-of-type) {
  height: 82px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 16px 0;
  border-top: 1px solid var(--inverted);
  counter-increment: main-counter;
}
main section[style*=projects] ol > li:not(:first-of-type):before {
  display: block;
  content: counter(main-counter);
  width: calc(5% - 16.6px);
  position: relative;
  font-size: 16px;
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li:not(:first-of-type):before {
    width: calc(10% - 15px);
    margin: auto 0;
  }
}
main section[style*=projects] ol > li:not(:first-of-type):nth-of-type(-n + 10):before {
  content: "0" counter(main-counter);
}
main section[style*=projects] ol > li:last-of-type {
  border-bottom: 1px solid var(--inverted);
}
main section[style*=projects] ol > li > span,
main section[style*=projects] ol > li > a {
  margin: auto 0;
}
main section[style*=projects] ol > li > span:first-child,
main section[style*=projects] ol > li > a:first-child {
  width: calc(30% - 16.6px);
  font-size: 20px;
  text-decoration: none;
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li > span:first-child,
  main section[style*=projects] ol > li > a:first-child {
    width: calc(60% - 13px);
    font-size: 16px;
  }
}
main section[style*=projects] ol > li > span:nth-child(2),
main section[style*=projects] ol > li > a:nth-child(2) {
  width: calc(8% - 16.6px);
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li > span:nth-child(2),
  main section[style*=projects] ol > li > a:nth-child(2) {
    width: calc(15% - 15px);
  }
}
main section[style*=projects] ol > li > span:nth-child(3),
main section[style*=projects] ol > li > a:nth-child(3) {
  width: calc(22% - 16.6px);
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li > span:nth-child(3),
  main section[style*=projects] ol > li > a:nth-child(3) {
    display: none;
  }
}
main section[style*=projects] ol > li > span:nth-child(4),
main section[style*=projects] ol > li > a:nth-child(4) {
  width: calc(15% - 16.6px);
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li > span:nth-child(4),
  main section[style*=projects] ol > li > a:nth-child(4) {
    display: none;
  }
}
main section[style*=projects] ol > li > span:last-child,
main section[style*=projects] ol > li > a:last-child {
  width: calc(20% - 16.6px);
  text-align: right;
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li > span:last-child,
  main section[style*=projects] ol > li > a:last-child {
    width: calc(15% - 15px);
  }
}
main section[style*=projects] ol > li > span:last-child svg,
main section[style*=projects] ol > li > a:last-child svg {
  color: var(--inverted);
}
@media only screen and (max-width: 1024px) {
  main section[style*=projects] ol > li > span:last-child svg,
  main section[style*=projects] ol > li > a:last-child svg {
    width: 36px;
    height: auto;
  }
}
main section[style*=testimonials] {
  padding: 0;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  main section[style*=testimonials] {
    padding: 135px 0 100px;
  }
}
main section[style*=testimonials] ul {
  height: 100vh;
  gap: 200px;
  transform: translate(calc((100vw - 1240px) / 2), 0);
  -webkit-text-size-adjust: 100%;
}
@media only screen and (max-width: 1480px) {
  main section[style*=testimonials] ul {
    gap: 100px;
    transform: translate(calc((100vw - 1000px) / 2), 0);
  }
}
@media only screen and (max-width: 1024px) {
  main section[style*=testimonials] ul {
    gap: 0;
    transform: none;
  }
}
@media only screen and (max-width: 767px) {
  main section[style*=testimonials] ul {
    height: auto;
  }
}
main section[style*=testimonials] ul,
main section[style*=testimonials] ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  main section[style*=testimonials] ul,
  main section[style*=testimonials] ul li {
    align-items: flex-start;
  }
}
main section[style*=testimonials] ul li {
  width: 100%;
  max-width: 1240px;
  flex-shrink: 0;
  gap: 100px;
}
@media only screen and (max-width: 1480px) {
  main section[style*=testimonials] ul li {
    max-width: 1000px;
    gap: 80px;
  }
}
@media only screen and (max-width: 1024px) {
  main section[style*=testimonials] ul li {
    flex-direction: column;
    align-items: flex-start;
    max-width: 600px;
    gap: 40px;
  }
}
@media only screen and (max-width: 767px) {
  main section[style*=testimonials] ul li {
    width: 100%;
    gap: 20px;
    padding: 0 30px;
  }
}
@media only screen and (max-width: 475px) {
  main section[style*=testimonials] ul li:first-of-type blockquote p:last-of-type, main section[style*=testimonials] ul li:nth-of-type(3) blockquote p:last-of-type {
    display: none;
  }
}
@media only screen and (max-width: 475px) {
  main section[style*=testimonials] ul li:nth-of-type(2) blockquote p:first-of-type {
    display: none;
  }
}
main section[style*=testimonials] ul li figure {
  width: 350px;
  height: 450px;
}
@media only screen and (max-width: 1480px) {
  main section[style*=testimonials] ul li figure {
    width: 262px;
    height: 338px;
  }
}
@media only screen and (max-width: 1024px) {
  main section[style*=testimonials] ul li figure {
    width: 125px;
    height: 160px;
  }
}
main section[style*=testimonials] ul li figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
main section[style*=testimonials] ul li blockquote {
  margin: 0 0 40px;
}
@media only screen and (max-width: 767px) {
  main section[style*=testimonials] ul li blockquote {
    margin: 0 0 25px;
  }
}
main section[style*=testimonials] ul li blockquote p {
  font-size: 20px;
  color: var(--inverted);
}
@media only screen and (max-width: 1480px) {
  main section[style*=testimonials] ul li blockquote p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  main section[style*=testimonials] ul li blockquote p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 475px) {
  main section[style*=testimonials] ul li blockquote p {
    font-size: 17px;
  }
}
main section[style*=testimonials] ul li cite strong,
main section[style*=testimonials] ul li cite span {
  display: block;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
}
main section[style*=testimonials] ul li cite strong {
  font-size: 16px;
}
@media only screen and (max-width: 1480px) {
  main section[style*=testimonials] ul li cite strong {
    font-size: 15px;
  }
}
@media only screen and (max-width: 767px) {
  main section[style*=testimonials] ul li cite strong {
    font-size: 12px;
  }
}
@media only screen and (max-width: 475px) {
  main section[style*=testimonials] ul li cite strong {
    font-size: 14px;
  }
}
main section[style*=testimonials] ul li cite span {
  font-size: 13px;
}
@media only screen and (max-width: 767px) {
  main section[style*=testimonials] ul li cite span {
    font-size: 12px;
  }
}
@media only screen and (max-width: 475px) {
  main section[style*=testimonials] ul li cite span {
    font-size: 13px;
  }
}
main section[style*=contact] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 160px;
}
@media only screen and (max-width: 1024px) {
  main section[style*=contact] {
    padding-bottom: 80px;
  }
}
main section[style*=contact] div {
  width: 100%;
  max-width: 800px;
}
main section[style*=contact] h2 {
  margin: 0 0 30px;
}
main section[style*=contact] ul {
  max-height: calc(100vh - 138px);
  padding: 0 25px 25px 0;
}
main section[style*=contact] ul .simplebar-vertical {
  top: 16px;
  bottom: 16px;
}
main section[style*=contact] ul .simplebar-vertical .simplebar-scrollbar:before {
  opacity: 0.1;
}
main section[style*=contact] ul .simplebar-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
main section[style*=contact] ul li {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
main section[style*=contact] ul li p {
  position: relative;
  width: auto;
  max-width: 430px;
  padding: 12px 16px;
  margin: 0;
  border-radius: 10px;
  border: 1px solid var(--inverted);
  line-height: 135%;
  animation: var(--anim);
}
main section[style*=contact] ul li p .btn {
  position: absolute;
  left: calc(100% + 12px);
  top: 50%;
  white-space: nowrap;
  transform: translate(0, -50%);
}
main section[style*=contact] ul li.in {
  padding-right: 4px;
}
main section[style*=contact] ul li.in p {
  margin-left: auto;
  color: var(--straight);
  background-color: var(--inverted);
}
main section[style*=contact] ul li.in p:last-of-type {
  border-radius: 10px 10px 0 10px;
}
main section[style*=contact] ul li.out {
  padding-left: 35px;
}
main section[style*=contact] ul li.out p {
  margin-right: auto;
  color: var(--inverted);
}
main section[style*=contact] ul li.out p:last-of-type {
  border-radius: 10px 10px 10px 0;
}
main section[style*=contact] ul li.out p a {
  font-weight: 700;
  text-decoration: underline;
}
main section[style*=contact] ul li.out p.available strong:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin: 0 8px 0 0;
  position: relative;
  top: -1px;
  border-radius: 50%;
  background-color: #03C988;
}
@media only screen and (max-width: 475px) {
  main section[style*=contact] ul li.out p.available strong:before {
    top: 0;
  }
}
main section[style*=contact] ul li.out figure {
  position: absolute;
  left: 0;
  bottom: 0;
}
main section[style*=contact] form {
  position: relative;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
main section[style*=contact] form label {
  position: relative;
  width: 100%;
}
main section[style*=contact] form label input.btn {
  width: 100%;
  padding: 17px 20px;
  font-family: var(--text-font);
  font-size: 16px;
  color: var(--inverted);
  border-radius: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--inverted);
  cursor: text;
  background: none;
}
@media only screen and (max-width: 1024px) {
  main section[style*=contact] form label input.btn {
    font-size: 14px;
  }
}
main section[style*=contact] form label input.btn::-webkit-input-placeholder {
  color: var(--inverted);
}

main section[style*=contact] form label input.btn:-moz-placeholder {
  color: var(--inverted);
}

main section[style*=contact] form label input.btn::-moz-placeholder {
  color: var(--inverted);
}

main section[style*=contact] form label input.btn:-ms-input-placeholder {
  color: var(--inverted);
}

main section[style*=contact] form label input.btn[disabled]::-webkit-input-placeholder {
  color: var(--straight);
}

main section[style*=contact] form label input.btn[disabled]:-moz-placeholder {
  color: var(--straight);
}

main section[style*=contact] form label input.btn[disabled]::-moz-placeholder {
  color: var(--straight);
}

main section[style*=contact] form label input.btn[disabled]:-ms-input-placeholder {
  color: var(--straight);
}

main section[style*=contact] form label input.btn:focus {
  border-bottom: 1px dashed var(--inverted);
  outline: none !important;
}
main section[style*=contact] form label svg {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 20px;
  top: calc(50% - 35px);
  display: none;
}
main section[style*=contact] form button[type=submit] {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--inverted);
  border: none;
  border-bottom: 1px solid var(--inverted);
}
@media only screen and (max-width: 1024px) {
  main section[style*=contact] form button[type=submit] {
    width: 57px;
    height: 57px;
  }
}
main section[style*=contact] form button[type=submit]:not([disabled]) {
  cursor: pointer;
  transition: 0.2s all;
}
@media only screen and (min-width: 1025px) {
  main section[style*=contact] form button[type=submit]:not([disabled]):hover {
    color: var(--straight);
    background: var(--inverted);
  }
}
main section[style*=contact] form button[type=submit][disabled] {
  cursor: not-allowed;
}
main section[style*=contact] form button[type=submit] svg {
  width: 24px;
  height: 24px;
}
main section[style*=contact] form button[type=button] {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  position: absolute;
  top: 50%;
  right: 20px;
  font-family: var(--text-font);
  font-size: 12px;
  font-weight: 600;
  color: var(--inverted);
  text-decoration: underline;
  white-space: nowrap;
  transform: translate(0, -50%);
}

footer {
  padding: 0 20px;
}
footer > ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 0;
  border-top: 1px solid var(--inverted);
}
@media only screen and (max-width: 1024px) {
  footer > ul {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px 0;
  }
}
footer > ul > li:first-of-type {
  margin: 0 50px 0 0;
}
footer > ul > li:first-of-type > a {
  display: block;
  line-height: 1;
}
footer > ul > li:first-of-type > a svg {
  width: auto;
  height: 35px;
  fill: var(--inverted);
}
@media only screen and (max-width: 1024px) {
  footer > ul > li:first-of-type > a svg {
    height: 25px;
  }
}
footer > ul > li:first-of-type > a svg:hover {
  animation: svg-color-switch 3s infinite linear;
}
footer > ul > li:nth-of-type(2) ul li {
  line-height: 1;
}
footer > ul > li:nth-of-type(2) ul li:not(:last-child) {
  margin: 0 0 9px;
}
footer > ul > li:last-of-type {
  margin-left: auto;
  padding-right: 10px;
}
@media only screen and (max-width: 1024px) {
  footer > ul > li:last-of-type {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }
}
@media only screen and (max-width: 1024px) {
  footer > ul > li:last-of-type span {
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  footer > ul > li:last-of-type span {
    font-size: 12px;
  }
}