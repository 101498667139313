// Fonts
$text-font: 'Space Mono', sans-serif;

// Colors
$white: #fff;
$black: #000;

$dark-1: #030618;
$green-1: #03C988;

$anim-color-1: #FE45B2;
$anim-color-2: #A235FF;
$anim-color-3: #1CE4C9;
$anim-color-4: #FFD437;
$anim-color-5: #00C4FF;
$anim-color-6: #E55807;

// Gradients
$gradients: (
);

// Shadows
$shadows: (
);

@keyframes text-color-switch {

  0% {
    color: $anim-color-1;
  }

  20% {
    color: $anim-color-2;
  }

  40% {
    color: $anim-color-3;
  }

  60% {
    color: $anim-color-4;
  }

  80% {
    color: $anim-color-5;
  }

  100% {
    color: $anim-color-6;
  }
}

@keyframes svg-color-switch {

  0% {
    fill: $anim-color-1;
  }

  20% {
    fill: $anim-color-2;
  }

  40% {
    fill: $anim-color-3;
  }

  60% {
    fill: $anim-color-4;
  }

  80% {
    fill: $anim-color-5;
  }

  100% {
    fill: $anim-color-6;
  }
}

@keyframes bubble-appear {

  from { 
    opacity: 0;
    transform: translate(0, 20px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}